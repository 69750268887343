import { notification } from 'antd'
import Error from '../assets/images/error.png'
import CloseIcon from '../assets/images/iconClose.png'
import { t } from 'i18next'

export const showErrorNotification = (message?: string) => {
  notification.open({
    className: 'noti noti-error',
    message: (
      <div className="flex items-center">
        <img src={Error} alt="error" width={50} style={{ marginRight: 10 }} />
        {message || t('errorHasOccurred')}
      </div>
    ),
    placement: 'topRight',
    closeIcon: <img src={CloseIcon} alt="close" />,
    duration: 5,
  })
}
