import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ComArticle,
  Commande,
  CommandeArrayInterface,
  Conditionnement,
  TotalAccessoriesApi,
} from '../../models'
import { StatusAPI } from '../../enum'
import { Metadata } from '../../models/common/common'
import { ActionRealtimeType } from '../../proto/type'
import { CommandeCreatePayload } from '../../http/commandeApi'

export interface GetAllPayload {
  limit: Number
  offset: Number
}

interface currentCommandeObject {
  status: StatusAPI
  commande?: Commande
  error: string
  loading: boolean
}

interface currentCommandeList {
  status: StatusAPI
  commandeList: Commande[]
  loading: boolean
  metadata: Metadata
}

interface totalCommandeList {
  status: StatusAPI
  number: number
  loading: boolean
}

export interface commandeState {
  currentCommande: currentCommandeObject
  currentListCommande: currentCommandeList
  totalCommande: totalCommandeList
  error: string
}

const initialState: commandeState = {
  currentCommande: {
    status: StatusAPI.initial,
    commande: undefined,
    error: '',
    loading: false,
  },
  currentListCommande: {
    status: StatusAPI.initial,
    commandeList: [],
    loading: false,
    metadata: {
      count: 0,
      limit: 0,
      total: 0,
    },
  },
  totalCommande: {
    status: StatusAPI.initial,
    number: 0,
    loading: false,
  },
  error: '',
}

const commandeSlice = createSlice({
  name: 'commande',
  initialState,
  reducers: {
    //Create Commande
    initialCommande(state) {
      state.currentCommande = initialState.currentCommande
    },
    clearList(state) {
      state.currentListCommande = initialState.currentListCommande
    },
    clearTotalNumber(state) {
      state.totalCommande = initialState.totalCommande
    },
    createCommande(state, action: PayloadAction<CommandeCreatePayload>) {
      state.currentCommande.status = StatusAPI.calling
      state.currentCommande.loading = true
    },
    createCommandeSuccess(state, action: PayloadAction<Commande>) {
      state.currentCommande.status = StatusAPI.success
      state.currentCommande.commande = action.payload
      state.currentCommande.loading = false
    },
    createCommandeFailed(state, action: PayloadAction<string>) {
      state.currentCommande.status = StatusAPI.failure
      state.currentCommande.error = action.payload
      state.currentCommande.loading = false
    },
    //getAll Commande
    getAllCommande(state, action: PayloadAction<{ isRealTime: boolean }>) {
      state.currentListCommande.status = StatusAPI.calling
      state.currentListCommande.loading = !action.payload.isRealTime
    },
    getAllCommandeSuccess(
      state,
      action: PayloadAction<CommandeArrayInterface>
    ) {
      state.currentListCommande.status = StatusAPI.success
      state.currentListCommande.commandeList = action.payload.data.entry
      state.currentListCommande.loading = false
      state.currentListCommande.metadata = action.payload.data.metadata
    },
    getAllCommandeFailed(state, action: PayloadAction<any>) {
      state.currentListCommande.status = StatusAPI.failure
      state.error = action.payload
      state.currentListCommande.loading = false
    },
    //get All Commande without filter condition
    getTotalCommande(state, action: PayloadAction) {
      state.totalCommande.status = StatusAPI.calling
      state.totalCommande.loading = true
    },
    getTotalCommandeSuccess(state, action: PayloadAction<TotalAccessoriesApi>) {
      state.totalCommande.status = StatusAPI.success
      state.totalCommande.number = action.payload.data.total
      state.totalCommande.loading = false
    },
    getTotalCommandeFailed(state, action: PayloadAction<any>) {
      state.totalCommande.status = StatusAPI.failure
      state.error = action.payload
      state.totalCommande.loading = false
    },
    setCommandeListRealtime(
      state,
      action: PayloadAction<{
        commandeChange: Commande
        actionType: ActionRealtimeType
      }>
    ) {},
    updateCommandeList(
      state,
      action: PayloadAction<{
        commandeChange: Commande
        actionType: ActionRealtimeType
        totalRecord?: number
      }>
    ) {
      let data = state.currentListCommande.commandeList
      const currentDataCount = state.currentListCommande.metadata.total
      const searchParams = new URLSearchParams(window.location.search)
      switch (action.payload.actionType) {
        case ActionRealtimeType.UPDATE:
          const index = data.findIndex(
            (item) => item.id === action.payload.commandeChange.id
          )
          if (index !== -1) {
            // overwrite the data of updated commande except chrono
            data[index] = {
              ...action.payload.commandeChange,
              chrono: data[index].chrono,
            }
            state.currentListCommande.commandeList = data
          }
          break
        case ActionRealtimeType.CREATE:
          // increment total number of commande
          state.totalCommande.number = state.totalCommande.number
            ? state.totalCommande.number + 1
            : 1
          // update number of records in current page of commande
          state.currentListCommande.metadata.total =
            action.payload.totalRecord || 0
          // add new commande to list if it is not full and new record is fit to current filter condition
          if (
            (!data ||
              data?.length < parseInt(searchParams.get('page-size') || '0')) &&
            action.payload.totalRecord &&
            (!currentDataCount || currentDataCount < action.payload.totalRecord)
          ) {
            if (data) {
              data.push(action.payload.commandeChange)
            } else {
              data = [action.payload.commandeChange]
            }
            state.currentListCommande.commandeList = data
          }
          break
        case ActionRealtimeType.DELETE:
          // decrease total number of commande
          state.totalCommande.number = state.totalCommande.number
            ? state.totalCommande.number - 1
            : 0
          state.currentListCommande.metadata.total =
            action.payload.totalRecord || 0
          // remove deleted commande from list if it is not full and deleted record is found in current list
          if (currentDataCount > (action.payload.totalRecord || 0)) {
            const index = data.findIndex(
              (item) => item.id === action.payload.commandeChange.id
            )
            if (index !== -1) {
              data.splice(index, 1)
              state.currentListCommande.commandeList = data
            }
          }
          break
      }
    },
    // update all com_article in list commande when reference related change
    updateReferenceCommande(state, action: PayloadAction<any>) {
      state.currentListCommande.commandeList =
        state.currentListCommande.commandeList?.map((item) => {
          return {
            ...item,
            com_article: item.com_article?.map((article) => {
              if (article.reference_id !== action.payload.id) {
                return article
              } else {
                const cdnData = action.payload.conditionnement?.find(
                  (cdn: Conditionnement) =>
                    cdn.niveau === article.conditionement_level
                )
                return {
                  ...article,
                  conditionnement: cdnData,
                  reference_product: action.payload,
                }
              }
            }),
          }
        })
    },
    // update a com_article in list commande
    updateComArticle(state, action: PayloadAction<ComArticle>) {
      for (let i = 0; i < state.currentListCommande.commandeList.length; i++) {
        const item = state.currentListCommande.commandeList[i]
        const ComArticleChangeIndex = item.com_article?.findIndex(
          (attProduction) => attProduction.id === action.payload.id
        )
        if (ComArticleChangeIndex !== -1) {
          state.currentListCommande.commandeList[i].com_article[
            ComArticleChangeIndex
          ] = {
            ...state.currentListCommande.commandeList[i].com_article[
              ComArticleChangeIndex
            ],
            ...action.payload,
          }
          break
        }
      }
    },
  },
})

const commandeReducer = commandeSlice.reducer
export default commandeReducer

export const {
  initialCommande,
  getAllCommande,
  getTotalCommande,
  getAllCommandeSuccess,
  getAllCommandeFailed,
  createCommande,
  createCommandeSuccess,
  createCommandeFailed,
  getTotalCommandeSuccess,
  getTotalCommandeFailed,
  clearList,
  clearTotalNumber,
  setCommandeListRealtime,
  updateCommandeList,
  updateReferenceCommande,
} = commandeSlice.actions
